<template src="./recommendation.html"></template>

<script>
import MainHeader from '../parts/header';
export default {
  name: 'GondolaMiniRecommendation',
  components: {
    MainHeader,
  },
};
</script>
